import React, { useMemo } from 'react'
import { withTheme } from 'styled-components'

import { ensureObject, titleCase } from '@agnostack/lib-core'

import TemplatedPage from '../components/molecules/TemplatedPage'
import { SEO } from '../components/atoms'
import { getTitle } from '../gatsby'

export default withTheme(({ pageContext }) => {
  const {
    node: {
      frontmatter: {
        title: pageTitle,
        canonicalPath,
        subtitle,
        category,
        format,
        defaultFormat,
        imagePath,
        path,
        keywords,
        description,
        template,
        siteMetadata: {
          tagline,
        } = {},
      } = {},
    } = {},
  } = ensureObject(pageContext)

  const { title, headline, pageTagline } = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _headline = getTitle({ context: pageContext })
    // eslint-disable-next-line no-underscore-dangle
    const _title = _headline === titleCase(template) ? `${_headline}: ${tagline}` : _headline

    return {
      title: _title,
      headline: _headline,
      pageTagline: subtitle || (_headline === pageTitle
        ? category
        : pageTitle),
    }
  }, [pageContext, category, pageTitle, subtitle, tagline, template])

  return (
    <>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        path={path}
        canonicalPath={canonicalPath}
        imagePath={imagePath}
        type="article"
      />
      <TemplatedPage
        data={pageContext}
        headline={headline}
        tagline={pageTagline}
        format={format || defaultFormat}
      />
    </>
  )
})
