/* eslint-disable no-underscore-dangle */
import React, { useCallback, useContext, useMemo } from 'react'
import { navigate } from 'gatsby'

import { ensureArray } from '@agnostack/lib-core'
import { useSafeEffect } from '@agnostack/lib-utils-react'

import PageFragment from '../PageFragment'
import { useQueryParam } from '../../../hooks'
import { Heading } from '../../atoms'
import {
  GlobalState,
  GlobalDispatch,
  GLOBAL_PARAMS,
  GLOBAL_ACTIONS,
  LISTING_NAMES,
  LISTINGS,
} from '../../../util'
import {
  ListingsWrapper,
  StyledAppListings,
  HeadingWrapper,
  PageWrapper,
  Description,
} from './ConditionalPage.style'

const ConditionalPage = ({ fragments, listings, children, ...renderProps }) => {
  const listingParam = useQueryParam(GLOBAL_PARAMS.LISTING)
  const dispatch = useContext(GlobalDispatch)
  const { [GLOBAL_PARAMS.LISTING]: listingContext } = useContext(GlobalState)

  useSafeEffect(() => {
    if (LISTING_NAMES.length === 1) {
      dispatch({
        type: GLOBAL_ACTIONS.ACTIVE_LISTING,
        payload: { [GLOBAL_PARAMS.LISTING]: LISTING_NAMES[0] },
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNavigate = useCallback((selectedListing, siteURL) => {
    if (!listings?.includes(selectedListing)) {
      navigate(siteURL)
    }
  }, [listings])

  const { showPage, activeListing, hasMultipeListings } = useMemo(() => {
    const pagelistings = ensureArray(listings)
    const _activeListing = (pagelistings.length === 1) ? pagelistings[0] : listingParam || listingContext
    const _hasMultipeListings = ((pagelistings.length > 1) && (LISTING_NAMES.length > 1))

    return {
      showPage: (!_hasMultipeListings || pagelistings.includes(_activeListing)),
      activeListing: _activeListing,
      hasMultipeListings: _hasMultipeListings,
    }
  }, [listings, listingParam, listingContext])

  const fragment = useMemo(() => (
    ensureArray(fragments).find(({
      node: {
        frontmatter: {
          match,
        } = {},
      } = {},
    }) => (match === 'listing-agnostack'))
  ), [fragments])

  return (
    <>
      {hasMultipeListings && (
        <>
          <ListingsWrapper>
            <StyledAppListings
              listing={activeListing}
              listings={listings}
              onSelect={handleNavigate}
            />
          </ListingsWrapper>
          {!showPage && (
            <HeadingWrapper>
              <Heading tag="2">
                Zendesk Apps
              </Heading>
              <Description>
                Select one of our Zendesk Apps above to get details on the installation and configuration steps.
              </Description>
            </HeadingWrapper>
          )}
        </>
      )}
      {showPage ? (
        <>
          {(activeListing && hasMultipeListings) && (
            <HeadingWrapper>
              <Heading tag="2">
                {LISTINGS[activeListing]?.name}
              </Heading>
            </HeadingWrapper>
          )}
          <div data-type="conditional-page" data-listing={activeListing}>
            {children}
          </div>
        </>
      ) : (
        <PageWrapper>
          <PageFragment data={fragment} fragments={fragments} />
        </PageWrapper>
      )}
    </>
  )
}

export default ConditionalPage
