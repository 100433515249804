import styled from 'styled-components'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'
import { ButtonGroup } from '@zendeskgarden/react-buttons'
import { Heading, Section } from '../../atoms'

export const PageHeading = styled(Heading).attrs(() => ({
  tag: '1',
}))`
  flex-grow: 1;
`

export const LeftCol = styled(Col)`
  margin-left: -2px;
  height: ${({ height }) => `${height}px`};
  align-self: auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 100%;
    align-items: center;
  }
`

export const BodyCol = styled(Col)`
  align-self: auto;
  margin-left: 2vw;
  margin-top: 8px;
  padding-top: 5em;
  flex-basis: 90%;
  ${({ 'data-sidebar': hasSidebar, theme }) => `
    max-width: ${theme.breakpoints.lg};
    @media (min-width: ${theme.breakpoints.lg}) {
      ${hasSidebar ? `
        flex-basis: 70%;
        max-width: 70%;
      ` : ''}
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      padding-top: 2em;
      margin-left: 0;
      max-width: 100%;
      align-items: center;
    }
  `}
`

export const PageWrapper = styled.div`
  margin-bottom: 2em;
`

export const CTAWrapper = styled.div`
  align-self: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    align-self: flex-end;
  }
`

export const ContentSection = styled(Section)`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.md}) {
      padding-top: 0;
    }
  `}
`

export const HeaderCol = styled(Col).attrs(() => ({
  md: 11,
}))`
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`

export const ContentGrid = styled(Grid)`
  min-height: 60vh;

  ${({ theme }) => `
    padding-left: 0px;
    padding-right: 0px;

    @media (min-width: ${theme.breakpoints.sm}) {
      padding-left: unset;
      padding-right: unset;
    }
  `}
`

export const ContentRow = styled(Row)`
  padding-bottom: 40px;
`

export const StyledNavigation = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 80px;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: center;
  }
`
