/* eslint-disable eqeqeq */
import React, { useMemo } from 'react'
import { withTheme } from 'styled-components'

import { Grid, Row } from '@zendeskgarden/react-grid'

import {
  titleCase,
  ensureObject,
  splitCommas,
  convertFormatted,
  DATE_FORMAT_MED,
} from '@agnostack/lib-core'

import { Heading, Section } from '../../atoms'

import LearnMore from '../LearnMore'
import PageFragment from '../PageFragment'
import ConditionalPage from '../ConditionalPage'

import { Tagline, HeroTitle } from '../../../styles/core'
import {
  LeftCol,
  BodyCol,
  HeaderCol,
  PageHeading,
  ContentGrid,
  ContentRow,
  ContentSection,
  StyledNavigation,
  PageWrapper,
  CTAWrapper,
} from './TemplatedPage.style'

const TemplatedPage = ({
  CTA,
  data,
  format,
  headline,
  navigation,
  tagline = ' ',
  children: sidebar,
  title: _title,
  header: Header,
}) => {
  const {
    children,
    fragments,
    node: {
      frontmatter: {
        date,
        title: frontMatterTitle,
        listing: frontMatterListing,
      } = {},
    } = {},
  } = ensureObject(data)

  const formattedDate = convertFormatted({
    value: date,
    from: { format: DATE_FORMAT_MED },
    to: { format: 'cccc MMM d, yyyy' },
  })

  const hasSidebar = useMemo(() => (
    sidebar != undefined
  ), [sidebar])

  const title = useMemo(() => (
    _title || frontMatterTitle
  ), [_title, frontMatterTitle])

  const listings = useMemo(() => (
    splitCommas(frontMatterListing)
  ), [frontMatterListing])

  return (
    <>
      <Section format={format} mode="thirdscreen">
        <Grid>
          <Row>
            <HeaderCol>
              <PageHeading>
                <HeroTitle>{titleCase(headline)}</HeroTitle>
                <Tagline>{tagline}</Tagline>
              </PageHeading>
              {CTA && (
                <CTAWrapper>
                  <CTA />
                </CTAWrapper>
              )}
            </HeaderCol>
          </Row>
        </Grid>
      </Section>
      <ContentSection format="light">
        <ContentGrid id="content_grid">
          <ContentRow>
            {hasSidebar && (
              <LeftCol md={12} lg={3}>
                {sidebar}
              </LeftCol>
            )}
            <BodyCol md={12} data-sidebar={hasSidebar}>
              {formattedDate && (
                <Heading tag="1">
                  {formattedDate}
                </Heading>
              )}
              {title && (
                <Heading tag="2" size="MD">
                  {title}
                </Heading>
              )}
              {Header && (
                <Header />
              )}
              <ConditionalPage fragments={fragments} listings={listings}>
                <PageFragment data={data} fragments={fragments} />
                <PageWrapper>
                  {children}
                </PageWrapper>
              </ConditionalPage>
              {navigation && (
                <StyledNavigation>{navigation}</StyledNavigation>
              )}
            </BodyCol>
          </ContentRow>
        </ContentGrid>
      </ContentSection>
      <LearnMore
        id="learn-more"
        mode="overlay"
        max="sm"
        format="light"
      />
    </>
  )
}

export default withTheme(TemplatedPage)
