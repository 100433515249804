import styled from 'styled-components'
import { Well } from '@zendeskgarden/react-notifications'

import { AppListings } from '../../atoms'

export const ListingsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledAppListings = styled(AppListings)`
  margin-bottom: 1em;
`

export const HeadingWrapper = styled.div`
  text-align: center;
`

export const Description = styled.h4`
  margin-bottom: 2em;
`

export const PageWrapper = styled(Well)`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 0 -4rem;
  }
`
